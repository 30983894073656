import { Link } from "gatsby";
import React from "react";

import './CallToActionButton.scss';

export const CallToActionButton = (props: {
  linkText: string;
  buttonText: string;
}) => {
  const internal = /^\/(?!\/)/.test(props.linkText)
  return internal ?
    <Link className="cta-btn btn btn-outline-dark" to={props.linkText}>
      {props.buttonText}
    </Link>
    :
    <a className="cta-btn btn btn-outline-dark" href={props.linkText}>
      { props.buttonText }
    </a>
};
