import React from 'react';
import AccordionPanel, { AccordionPanelModel } from './AccordionPanel';

import './Accordion.scss';

const Accordion = (props: AccordionModel) => {
  return (
    <div className="accordion-wrapper">
       <div className="accordion-wrapper container">
        {
          props.heading && 
          <h2 className="headline">
            { props.heading }
          </h2>
        }
        <div className="row">
          <div className="col">
            <div className="accordion" id="accordion">
              { 
                props.panels && props.panels.map(panel => {
                  return <AccordionPanel key={panel.heading} {...panel} />
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AccordionModel {
  heading: string;
  panels: AccordionPanelModel[];
}

export default Accordion;
