import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RenderRichTextData, ContentfulRichTextGatsbyReference, renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import React, { useEffect, useRef } from "react";
import { marked } from "marked";

import './Overview.scss';
import { CallToActionButton } from "../call-to-action-button/CallToActionButton";

const MARKDOWN_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return children;
    },
  },
};

const Overview = ({
  headline,
  subheadline,
  imageRight,
  image,
  markdownContent,
  ctaButton,
}: {
  headline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  subheadline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  imageRight: boolean;
  image: any;
  markdownContent: any;
  ctaButton: any; 
}) => {
  const bodyElement = useRef(null);
  useEffect(() => {
    if (bodyElement?.current) {
      const table = bodyElement.current.querySelector('table');
      if (table) {
        table.classList.add('table');
      }
    }
  }, []);
  return (
    <div className="gray-bg p-0">
      <div className="container-fluid">
        <div className={`row ${imageRight ? 'flex-row-reverse' : ''}`}>
          <div className="col-md-6 p-0">
            <div
              className="info-bg"
            >
              <GatsbyImage 
                alt=''
                style={{width: '100%'}}
                image={getImage(image)}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="info-details p-3 w-100">
              <div className="mb-4 overview-headline">
              { renderRichText(headline, MARKDOWN_OPTIONS) }
              </div>

              { subheadline && <h4 className="overview-subheadline mb-3">{ renderRichText(subheadline, MARKDOWN_OPTIONS) }</h4> }

              { markdownContent && <div ref={bodyElement} dangerouslySetInnerHTML={{__html: marked(markdownContent.markdownContent)}} /> }
            
              { ctaButton && <div className="mt-4 d-flex justify-content-center"><CallToActionButton {...ctaButton} /></div>}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;

