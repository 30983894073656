import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
  renderRichText,
} from "gatsby-source-contentful/rich-text";
import { marked } from "marked";
import React, { useEffect, useRef } from "react";
import { CallToActionButton } from "../call-to-action-button/CallToActionButton";
import "./CallToAction.scss";

const CallToAction = ({
  headline,
  subheadline,
  backgroundImage,
  content,
  callToAction,
}: {
  headline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  subheadline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  backgroundImage: any;
  content: any;
  callToAction: any;
}) => {

  const bodyElement = useRef(null);

  useEffect(() => {
    if (bodyElement?.current) {
      const table = bodyElement.current.querySelector('table');
      if (table) {
        table.classList.add('table');
      }
    }
  }, []);

  return (
    <div
      className={`call-to-action-wrapper ${backgroundImage && "has-bg-img"}`}
    >
      {backgroundImage && (
        <GatsbyImage
          style={{ gridArea: "1/1" }}
          alt="Call To Action"
          image={getImage(backgroundImage)}
        />
      )}
      <div className="container call-to-action-container p-3 p-md-5 pt-4 pt-md-4">
        <div className="row">
          <div className="section-title text-md-center">
              {headline && <div className="call-to-action-headline">{renderRichText(headline)}</div>}
              {subheadline && <h4 className="call-to-action-subheadline">{renderRichText(subheadline)}</h4>}
              {content && (
                <div ref={bodyElement}
                className="call-to-action-content"
                  dangerouslySetInnerHTML={{
                    __html: marked(content.content),
                  }}
                ></div>
              )}
            {callToAction && <div className="mt-4 d-flex justify-content-center"><CallToActionButton {...callToAction} /></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
