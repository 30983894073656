import { RenderRichTextData, ContentfulRichTextGatsbyReference, renderRichText } from 'gatsby-source-contentful/rich-text';
import React, { useState } from 'react';

const AccordionPanel = (props: AccordionPanelModel) => {

  const [isCollapsed, setCollapsed] = useState(!props.isExpandedDefault);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className={`accordion-button ${isCollapsed ? 'collapsed' : 'show'}`}
          type="button"
          onClick={() => setCollapsed(!isCollapsed)}
          aria-expanded={isCollapsed}
        >
          { props.heading }
        </button>
      </h2>
      <div
        className={`accordion-collapse ${isCollapsed ? 'collapse' : 'show'}`}
      >
        <div className="accordion-body">
          { renderRichText(props.body) }
        </div>
      </div>
    </div>
  );
};

export interface AccordionPanelModel {
  heading: string;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  isExpandedDefault?: boolean;
}
export default AccordionPanel;
