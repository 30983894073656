import { graphql } from "gatsby";
import React from "react";
import { componentMappings } from "../components";

import "bootstrap/scss/bootstrap.scss";
import NavBar from "../components/nav/NavBar";
import Footer from "../components/footer/Footer";

import './index.scss';
import { SEO } from "../components/seo/seo";

const ContentfulPage = (props: any): React.ReactElement<any> => {
  return <>
    <NavBar />
    {
      props.data.contentfulPage.pageComponents.map((pageComponent, index) => {
        const DynamicComponent = componentMappings.get(pageComponent.__typename);
        return <DynamicComponent key={`${pageComponent.__typename}_${index}`} {...pageComponent} />;
      })
    }
    <Footer />
  </>
};

export const query = graphql`
  query ($id: String) {
    contentfulPage(id: { eq: $id }) {
      title
      pageMetadata {
        ... on ContentfulPageMetadata {
          title
          description
          keywords
          canonicalUrl
        }
      }
      pageComponents {
        ... on ContentfulHero {
          __typename
          headline {
            raw
          }
          subheadline {
            raw
          }
          backgroundImage {
            gatsbyImageData(height: 500)
          }
        }
        ... on ContentfulCallToAction {
          __typename
          headline {
            raw
          }
          subheadline {
            raw
          }
          content {
            content
          }
          callToAction {
            ... on ContentfulCta {
              linkText
              buttonText
            }
          }
          backgroundImage {
            gatsbyImageData(height: 400)
          }
        }
        ... on ContentfulOverview {
          __typename
          headline {
            raw
          }
          subheadline {
            raw
          }
          imageRight
          image {
            gatsbyImageData
          }
          markdownContent {
            markdownContent
          }
          ctaButton {
            ... on ContentfulCta {
              linkText
              buttonText
            }
          }
        }
        ... on ContentfulAccordion {
          __typename
          heading: headline
          panels {
            ... on ContentfulAccordionPanel {
              heading
              body {
                raw
              }
            }
          }
        }
        ... on ContentfulGeneralContent {
          __typename
          content {
            content
          }
        }
        ... on ContentfulArticleCardSet {
          __typename
          eyebrow
          articles {
            ... on ContentfulArticle {
              title
              pagePath
              headline
              articleType
              subheadline {
                raw
              }
              hero {
                ... on ContentfulHero {
                  __typename
                  backgroundImage {
                    gatsbyImageData(height: 215, width: 370)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ContentfulPage;

export const Head = ({ data }) => {
  const { pageMetadata } = data.contentfulPage;
  return <SEO metadata={pageMetadata} />
}
