import React from "react";
import { marked } from "marked";

export const GeneralContent = (props: GeneralContentProps) => {
  const content = marked(props.content.content);
  return (
    <div className="container pt-5 pb-4" dangerouslySetInnerHTML={{__html: content }}></div>
  )
}

interface GeneralContentProps {
  content: {
    content: string;
  }
}